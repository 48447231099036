<template>
    <div class="flex">
        <aside class="card self-start w-[280px] mr-3">
            <SidebarTop :clientData="sidebarInformation" />

            <ul class="mt-3 space-y-2">
                <SideBarItem
                    routeName="client_dashboard"
                    :label="$t('client_details.dashboard')"
                    :icon="['far', 'chart-network']"
                />
                <SideBarItem
                    routeName="client_information"
                    :label="$t('client_details.general')"
                    :icon="['fas', 'info']"
                />
                <SideBarItem
                    routeName="client_billing_information"
                    :label="$t('client_details.billing_information')"
                    :icon="['far', 'money-check-edit-alt']"
                />
                <SideBarItem
                    v-if="userCan('read service history')"
                    routeName="client_history"
                    :label="$t('client_details.services_history')"
                    :icon="['far', 'broom']"
                />
                <SideBarItem
                    routeName="client_notes_comments"
                    :label="$t('client_details.notes_comments')"
                    :icon="['fad', 'comments']"
                />
                <SideBarItem
                    routeName="client_invoices"
                    :label="$t('client_details.invoices')"
                    :icon="['fad', 'file-invoice-dollar']"
                    :badge="sidebarInformation.invoices_count"
                />

                <!-- ADDRESS DYNAMIC LINK -->
                <SideBarItem
                    v-if="sidebarInformation.addresses_count === 1"
                    routeName="client_address_details"
                    :label="$t('client_details.addresses')"
                    :icon="['fas', 'home-alt']"
                    :badge="sidebarInformation.addresses_count"
                    :extraRouteParams="{ address_uuid: sidebarInformation.address_first_uuid }"
                />
                <SideBarItem
                    v-else
                    routeName="client_addresses"
                    :label="$t('client_details.addresses')"
                    :icon="['fas', 'home-alt']"
                    :badge="sidebarInformation.addresses_count"
                />
                <!-- / ADDRESS DYNAMIC LINK -->

                <SideBarItem
                    v-if="userCan('read keys')"
                    routeName="client_keys"
                    :label="$t('client_details.keys')"
                    :icon="['fas', 'key']"
                    :badge="sidebarInformation.keys_count"
                />
                <SideBarItem
                    routeName="client_damages"
                    :label="$t('client_details.damages')"
                    :icon="['fad', 'fragile']"
                    :badge="sidebarInformation.damages_count"
                />
                <SideBarItem
                    routeName="client_assigned_employees"
                    :label="$t('client_details.assigned_employees')"
                    :icon="['fad', 'users']"
                    :badge="sidebarInformation.assigned_employees_count"
                />
                <SideBarItem
                    routeName="client_map_poi"
                    :label="$t('client_details.poi')"
                    :icon="['fad', 'map-marked-alt']"
                    :badge="sidebarInformation.map_poi_count"
                />
                <SideBarItem
                    routeName="client_files"
                    :label="$t('client_details.files')"
                    :icon="['far', 'file-upload']"
                    :badge="sidebarInformation.files_count"
                />
                <SideBarItem
                    routeName="client_emergency_contacts"
                    :label="$t('client_details.emergency_contacts')"
                    :icon="['fad', 'hands-helping']"
                    :badge="sidebarInformation.emergency_contacts_count"
                />
                <SideBarItem
                    v-if="hasFortnox"
                    routeName="client_fortnox"
                    label="Fortnox"
                    :icon="['fad', 'hands-helping']"
                />

                <!-- <li class="py-1" :class="{ 'pl-2': activeMenu !== 'communication' }">
                    <router-link
                        :to="{ name: 'client_communication', params: { uuid: clientUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'communication' }"
                    >
                        <fa-icon
                        :icon="['fas', 'comment-dots']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('client_details.emails_messages') }}
                        </p>
                    </router-link>
                </li> -->
                <!-- <li class="py-1" :class="{ 'pl-2': activeMenu !== 'calendar' }">
                    <router-link
                        :to="{ name: 'client_details_information', params: { uuid: clientUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'calendar' }"
                    >
                        <fa-icon
                        :icon="['far', 'calendar-alt']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('client_details.calendar') }}
                        </p>
                    </router-link>
                </li> -->
                <!-- <li class="py-1" :class="{ 'pl-2': activeMenu !== 'contracts' }">
                    <router-link
                        :to="{ name: 'client_details_information', params: { uuid: clientUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'contracts' }"
                    >
                        <fa-icon
                        :icon="['far', 'file-signature']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('client_details.contracts') }}
                        </p>
                    </router-link>
                </li> -->
            </ul>
        </aside>
        <router-view v-if="clientId" :clientId="clientId" @clientDataChanged="getSidebarInformation" />
    </div>
</template>
<script>

export default {
    components: {
        SideBarItem: () => import(/* webpackChunkName: "ClientDetailsSideBarItem" */ './ClientDetailsSideBarItem'),
        SidebarTop:  () => import(/* webpackChunkName: "ClientDetailsSidebarTop" */ './ClientDetailsSidebarTop'),
    },


    beforeRouteUpdate(to, from, next) {
        this.clientUuid = to.params.uuid;
        this.getSidebarInformation();
        next();
    },

    computed: {
        hasFortnox() {
            return this.$store.state.user.user.has_fortnox;
        },
    },

    data() {
        return {
            clientId:           null,
            clientUuid:         this.$route.params.uuid,
            sidebarInformation: {},
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('clients.client_details'));
        this.$store.commit('setActiveMenuItem', '1-1');
        setTimeout(() => {
            if(!this.userCan('read clients')) {
                    this.$router.push({ name: 'calendar' });
                }
        }, 1000);
        this.getSidebarInformation();

        window.scrollTo(0, 0);
    },

    methods: {
        async getSidebarInformation() {
            this.$wait.start('loading.sidebar_information');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/sidebar_information`);
                this.clientId = data.id;
                this.sidebarInformation = data;
            } finally {
                this.$wait.end('loading.sidebar_information');
            }
            window.scrollTo(0, 0);
        },
    },
};
</script>
